@import url('https://fonts.googleapis.com/css2?family=BIZ+UDGothic&family=Bangers&family=Palette+Mosaic&display=swap');
@font-face {
  font-family: Mlk;
  src: url(fonts/Milk_and_Honey.ttf);

}
@font-face{
    font-family: Pk;
  src:url(fonts/Pokemon.ttf)
}



.changeClass{


    color:red;
}

.bg_image {
  width: 100%;
  height: 100vh;
}

body{
    width:100%;
    height:100vh;
    background-image: url('./images/yf.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
    
    // font-size:1.2rem;

}


.glow{
    transition-duration: .15s;
}
.glow:hover{
    color: gray;
    border-top: 5px solid #1C6EA4;
    border-bottom: 5px solid #1C6EA4;



}


.simon-hover{
    background:rgba(136, 133, 133, 0.308);
}
.simon-hover:hover{
    background:none;
}
.test{


    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    // padding: 200px 400px;
    border-radius:10px;
    max-width: 600px;
    // padding:100px 200px;
    padding: 10vh 10vw;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 48px 15px rgba(255,255,255,0.89); 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 48px 15px rgba(255,255,255,0.89);
    transition: 0.3s;
    .main-glow{
        background-color:rgb(187, 3, 3);
        padding: 5px 10px;
    }


  }
  .test:hover{
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 50px 15px #000000; 
box-shadow: 0px 10px 13px -7px #000000, 5px 5px 50px 15px #000000;
  }

div[class*=box] {
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .box-1 { background-color: #FF6766; }
  .box-2 { background-color: #3C3C3C; }
  .box-3 { background-color: #66A182; }
  
  .btn {
    line-height: 50px;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
  }
  
  /* 
  ========================
        BUTTON ONE
  ========================
  */
  .btn-one {
    color: #FFF;
    transition: all 0.3s;
    position: relative;
  
  }
  .btn-one span {
    transition: all 0.3s;
  }
  .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255,255,255,0.5);
    border-bottom-color: rgba(255,255,255,0.5);
    transform: scale(0.1, 1);
  }
  .btn-one:hover span {
    letter-spacing: 2px;
  }
  .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255,255,255,0.1);
  }
  .btn-one:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }
  
  .btn-two {
    color: #FFF;
    transition: all 0.5s;
    position: relative; 
  }
  .btn-two span {
    z-index: 2; 
    display: block;
    position: absolute;
    width: 100%;
    height: 100%; 
  }
  .btn-two::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: rgba(255,255,255,0.1);
  }
  .btn-two::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: rgba(255,255,255,0.1);
  }
  .btn-two:hover::before {
    // transform: rotate(-45deg);
    background-color: rgba(255,255,255,0);
  }
  .btn-two:hover::after {
    // transform: rotate(45deg);
    background-color: rgba(255,255,255,0);
  }
  
  
  /* 
  ========================
        BUTTON THREE
  ========================
  */
  .btn-three {
    color: #FFF;
    transition: all 0.5s;
    position: relative;
  }
  .btn-three::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255,255,255,0.1);
    transition: all 0.3s;
  }
  .btn-three:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
  }
  .btn-three::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0.5);
    transform: scale(1.2,1.2);
  }
  .btn-three:hover::after {
    opacity: 1;
    transform: scale(1,1);
  }

  .inc_dec{
    transition: .3s;
    cursor:pointer;
    user-select: none;
  }
  .inc_dec:hover{
    color:red;
  }

  .btn-slice {
    padding: 1.5rem 2.5rem;
    border: 2px solid #ffffff;
    border-radius: 0rem;
    color: #ffffff;
    font-size: 0.6475rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    position: relative;
    
    -webkit-transition: all 1000ms cubic-bezier(0.890, 0, 0.140, 1); /* older webkit */
    -webkit-transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -moz-transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -o-transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); /* custom */
  
    -webkit-transition-timing-function: cubic-bezier(0.890, 0, 0.140, 1); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -moz-transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -o-transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); /* custom */
  }
  
  .btn-slice .top  {
    position: relative;
    height: 6px;
    overflow: hidden;
    top: 0;
    -webkit-transition: all 300ms cubic-bezier(1.000, 0, 0.025, 1); /* older webkit */
    -webkit-transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -moz-transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -o-transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); /* custom */
  
    -webkit-transition-timing-function: cubic-bezier(1.000, 0, 0.025, 1); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -moz-transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -o-transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); /* custom */
    
    -webkit-transition-delay:0.35s;     
    -moz-transition-delay::0.35s; 
    -ms-transition-delay::0.35s;     
    -o-transition-delay::0.35s;     
    transition-delay::0.35s;
  }
  
  .btn-slice .bottom  {
    position: relative;
    height: 6px;
    overflow: hidden;
    bottom: 0;
    -webkit-transition: all 300ms cubic-bezier(1.000, 0, 0.025, 1); /* older webkit */
    -webkit-transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -moz-transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -o-transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040); /* custom */
  
    -webkit-transition-timing-function: cubic-bezier(1.000, 0, 0.025, 1); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -moz-transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    -o-transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); 
    transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040); /* custom */
    
    -webkit-transition-delay:0.35s;     
    -moz-transition-delay::0.35s; 
    -ms-transition-delay::0.35s;     
    -o-transition-delay::0.35s;     
    transition-delay::0.35s;
  }
  
  .btn-slice .bottom span {
    top: -6px;
    position: absolute;
    left: 0;
  }
  
  .btn-slice:hover .top {
    top: -5px;
    -webkit-transition-delay:0.35s;     
    -moz-transition-delay::0.35s; 
    -ms-transition-delay::0.35s;     
    -o-transition-delay::0.35s;     
    transition-delay::0.35s; 
  }
  
  .btn-slice:hover .bottom {
    bottom: -5px;
    -webkit-transition-delay:0.35s;     
    -moz-transition-delay::0.35s; 
    -ms-transition-delay::0.35s;     
    -o-transition-delay::0.35s;     
    transition-delay::0.35s; 
  }
  .btn-slice:hover, .btn-slice:focus, .btn-slice:active {
    margin-left: 10px;
  }
  .btn-slice:before{
    content: '';
    height: 1px;
    width: 60px;
    background-color: #ffffff;
    position: absolute;
    margin-top: 6px;
    right: -35px;
    
    -webkit-transition: all 1000ms cubic-bezier(0.890, 0, 0.140, 1); /* older webkit */
    -webkit-transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -moz-transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -o-transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225); /* custom */
  
    -webkit-transition-timing-function: cubic-bezier(0.890, 0, 0.140, 1); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -moz-transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    -o-transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); 
    transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225); /* custom */
  }
  .btn-slice:hover:before{
    width: 130%;
  }
